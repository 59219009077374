<template>
  <div class="contant-oc-detail-global">
    <div ref="searchOCDetailGlobal">
      <skeleton-searchbar-o-c-detail-component-global class="global-searchbar-detail" />
    </div>
    <div class="global-content-detail" :style="{ height: screenHeight + 'px' }">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-divider class="divider-global mt-9"></v-divider>
        <div class="content-title-add-material-detail-oc">
          <p class="text-title">
            <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
          </p>
          <v-spacer></v-spacer>
          <div class="content-btn-add-material-detail-oc" v-if="$route.name == 'OPPendingDetail'">
            <v-skeleton-loader width="auto" type="button"></v-skeleton-loader>
          </div>
        </div>

        <v-skeleton-loader width="auto" type="table"></v-skeleton-loader>
      </v-container>
    </div>
    <!-- <footer-component-global /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,
    };
  },

  mounted() {
    this.matchHeight();
    this.handleResize();
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.addEventListener("resize ", this.matchHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    matchHeight() {
      if (this.$refs.searchOCDetailGlobal !== undefined) {
        this.heightMax = this.$refs.searchOCDetailGlobal.clientHeight;
        this.heightMax = this.heightMax + 123;
      }
      if (window.innerWidth > 600) {
        this.screenHeight = window.innerHeight - this.heightMax;
      } else {
        this.screenHeight = window.innerHeight - this.heightMax;
      }
    },
    handleResize: function () {
      this.matchHeight();
    },
  },
};
</script>

<style scoped>
.content-title-add-material-detail-oc {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
}

.text-title {
  text-align: start;
  letter-spacing: 4.8px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  /* margin-right: 10px; */
  font-family: "pop-Bold";
  color: var(--primary-color-color-title-information);
}

.content-btn-add-material-detail-oc {
  width: 200px;
}

.content-color-id-raw-material {
  display: flex;
  justify-content: start;
  width: 100%;
}

.color-raw-material {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  margin-top: 2px;
  margin-right: 10px;
  border: 1px solid var(--primary-color-text);
}
</style>